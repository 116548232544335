import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export {dayjs}

type Dayjs = ReturnType<typeof dayjs>
type DateType = Date
type DateLike = string | DateType | Dayjs

export function returnDayjs(value?: DateLike) {
	return dayjs(value)
}
export function toEasternTime(value: DateLike) {
	return returnDayjs(value).tz('America/New_York', false)
}
export function roundToInterval(dateLike: DateLike, interval: number = 60, mode: 'lazy' | 'greedy' | 'balanced' = 'lazy') {
	let dayjsDate = returnDayjs(dateLike)

	let firstInterval
	if (mode === 'lazy') firstInterval = interval * 1.25
	else if (mode === 'greedy') firstInterval = interval * .75

	if (firstInterval) dayjsDate = roundToInterval(dayjsDate, firstInterval, 'balanced');

	const roundedMinute = Math.round(dayjsDate.minute() / interval) * interval;
	return dayjsDate.startOf('hour').add(roundedMinute, 'minute');
}
export function roundAtMinute(dateLike: DateLike, threshold: number = 45) {
	let dayjsDate = returnDayjs(dateLike)

	const minutes = dayjsDate.minute()
	dayjsDate = dayjsDate.startOf('hour')
	if (minutes >= threshold) dayjsDate = dayjsDate.add(1,'hour')

	return dayjsDate
}
function minDate({dateLike, buffer = 2, threshold = 45}: { dateLike: DateLike; buffer?: number; threshold?: number;}) {
	const roundedDate = roundAtMinute(dateLike, threshold);
	return roundedDate.add(buffer, 'hours');
}
type AppointmentDateObj =  {
	dayjs: Dayjs;
	date: Date;
	formatted: {
		dateTimeLocal: string;
		utc: string;
		date: string;
		time: string;
	}
}

/**
 * Class representing appointment rules and related date-time operations.
 *
 * @remarks
 * This class provides methods to handle appointment dates and times, including rounding to intervals,
 * converting to different time zones, and formatting dates. It uses the `dayjs` library for date manipulation.
 *
 * @example
 * ```typescript
 * const appointment = new AppointmentRules({ dateLike: '2023-10-01T10:00:00Z', bufferInHours: 3 });
 * console.log(appointment.initial.formatted.dateTimeLocal); // Outputs the initial date-time in local format
 * console.log(appointment.min.formatted.dateTimeLocal); // Outputs the minimum date-time considering the buffer
 * ```
 *
 * @note
 * Be careful where you instantiate AppointmentRules! Instantiating the class on the server will return server date and time.
 *
 * @param arProps - Optional properties to initialize the appointment rules.
 * @param arProps.dateLike - The initial date-like value to set the appointment.
 * @param arProps.bufferInHours - The buffer time in hours to add to the minimum date.
 *
 * @property bufferInHours - The buffer time in hours.
 * @property initial - The initial appointment date object.
 * @property min - The minimum appointment date object considering the buffer.
 * @property fallback - A fallback appointment date object.
 * @property formats - An object containing date-time formats.
 *
 * @method #makeDateObj - Creates an appointment date object from a date-like value.
 */
export class AppointmentRules {
	bufferInHours: number;
	initial: AppointmentDateObj;
	min: AppointmentDateObj;
	fallback: AppointmentDateObj;
	formats = {
		dateTimeLocal: `YYYY-MM-DD[T]HH:mm`,
		date: 'YYYY-MM-DD',
		time: 'HH:mm:ss',
		utc: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
		utcTrimmed: 'YYYY-MM-DDTHH:mm[Z]'
	}
	#makeDateObj(dateLike: DateLike) {
		const dayjsDate = returnDayjs(dateLike);
		const dateObj: AppointmentDateObj = {
			dayjs: dayjsDate,
			date: dayjsDate.toDate(),
			formatted: {
				dateTimeLocal: dayjsDate.format(this.formats.dateTimeLocal),
				utc: dayjsDate.toISOString(),
				date: dayjsDate.format(this.formats.date),
				time: dayjsDate.format(this.formats.time)
			}

		}
		return dateObj

	}
	constructor(arProps?: { dateLike?: DateLike, bufferInHours?: number }) {
		const initialDayjs = (arProps && arProps.dateLike) ? roundAtMinute(arProps.dateLike) : roundAtMinute(dayjs())
		this.bufferInHours = (arProps && arProps.bufferInHours) ? arProps.bufferInHours : 2;
		this.initial = this.#makeDateObj(initialDayjs);
		this.min = this.#makeDateObj(minDate({dateLike: this.initial.dayjs, buffer: this.bufferInHours}));
		this.fallback = this.#makeDateObj('1111-11-11T11:11');
	}
}
export function getExpiry({ date = dayjs(), threshold = 5, format = 'MM/DD/YYYY' }) {
	let dayjsDate = returnDayjs(date)
	if (dayjsDate) {
		const thisDay = dayjsDate.date()
		const daysLeft = dayjsDate.daysInMonth() - thisDay
		if (daysLeft < threshold) dayjsDate = dayjsDate.add(1, 'month')

		return dayjsDate.endOf('month').format(format)
	} else throw Error('getExpiry function was passed invalid date parameter')
}