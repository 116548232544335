import { twMerge } from 'tailwind-merge';
import { mergician } from 'mergician';

export const fieldTypes = 		['color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'month', 'number', 'password', 'range', 'search', 'tel', 'time', 'url', 'week', 'text', 'textarea', 'select', 'radio', 'checkbox', 'rating'];
export const fieldParts = 		['fieldWrapper', 'field', 'legend', 'label', 'validationMessage'];
export const focusStates = 	['unfocused', 'focused', 'hasValue'];
export const validStates = 	['valid', 'invalid'];

const baseKey = 'base' as const;
const fieldTypeKeys = 	['color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'month', 'number', 'password', 'range', 'search', 'tel', 'time', 'url', 'week', 'text', 'textarea', 'select', 'radio', 'checkbox', 'rating'] as const;
const fieldPartKeys = 	['fieldWrapper', 'field', 'legend', 'label', 'validationMessage'] as const;
const focusStateKeys = 	['unfocused', 'focused', 'hasValue'] as const;
const validStateKeys = 	['valid', 'invalid'] as const

type BaseKey = typeof baseKey;
type FieldTypeKey = typeof fieldTypeKeys[number];
type FieldPartKey = typeof fieldPartKeys[number];
type FocusStateKey = typeof focusStateKeys[number];
type ValidStateKey = typeof validStateKeys[number];


export type FieldType = typeof fieldTypeKeys[number];
export type FieldPart = typeof fieldPartKeys[number];
export type FocusState = typeof focusStateKeys[number];
export type ValidState = typeof validStateKeys[number];

type FocusStateObj = Record<ValidState, string>;
type FieldPartObj = Record<FocusState, FocusStateObj>;
type FieldTypeObj = Record<FieldPart, FieldPartObj>;

type BaseMap = Record<BaseKey, string>;
type FocusStateObjWithBaseClasses = BaseMap & FocusStateObj;
type FieldPartObjWithBaseClasses = BaseMap & Record<FocusState, Partial<FocusStateObjWithBaseClasses>>;
type FieldTypeObjWithBaseClasses = Record<FieldPartKey, Partial<FieldPartObjWithBaseClasses>>;
type ClassesByFieldType = Partial<Record<'default' | FieldTypeKey, Partial<FieldTypeObjWithBaseClasses>>>;

type FoundationClasses = typeof foundationClasses;
type ByFieldType = Record<'default', FieldTypeObj> & Partial<Record<FieldType, Partial<FieldTypeObj>>>;

const foundationClasses = {
	transition: 'transition-all duration-100',
	fieldGapX: 'gap-4',
	fieldPaddingX: 'px-4',
	fieldPaddingY: 'py-2.5',
	fieldPadding: '',
	fieldShape: 'rounded-md w-full',
	labelColor: 'text-gray-500',
	textColor: `text-slate-900`,
	textInvalid: `text-red-600`,
	textFocused: `text-blue-800`
}
foundationClasses.fieldPadding = `${foundationClasses.fieldPaddingX} ${foundationClasses.fieldPaddingY}`;

const classesByFieldType:ClassesByFieldType = {
	default: {
		fieldWrapper: {
			base: `flex flex-col group flex-auto relative`
		},
		field: {
			base: `flex border outline-none text-black ${foundationClasses.fieldShape} ${foundationClasses.fieldPadding} ${foundationClasses.transition}`,
			unfocused: {
				valid: `border-gray-300`,
				invalid: `border-red-300 placeholder-red-300`
			},
			focused: {
				base: `border-gray-200 ring-2 ring-inset ring-tee-blue-default`
			},
			hasValue: {
				valid: `border-gray-300`,
				invalid: `border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500`
			}
		},
		legend: {
			base: foundationClasses.labelColor
		},
		label: {
			base: `absolute top-0 left-0 z-10 flex items-center pointer-events-none ${foundationClasses.transition}`,
			unfocused: {
				base: `w-fit h-full ${foundationClasses.fieldPadding}`,
				valid: foundationClasses.labelColor,
				invalid: foundationClasses.labelColor
			},
			focused: {
				base: 'h-[2px] text-sm p-1 ml-2 -mt-1 bg-white',
				valid: foundationClasses.textFocused,
				invalid: foundationClasses.textFocused
			},
			hasValue: {
				base: 'h-[2px] text-sm p-1 ml-2 -mt-1 bg-white',
				valid: foundationClasses.labelColor,
				invalid: foundationClasses.labelColor
			}
		},
		validationMessage: {
			base: `${foundationClasses.fieldPaddingX} italic`,
			unfocused: {
				invalid: foundationClasses.textInvalid
			},
			focused: {
				invalid: foundationClasses.textInvalid
			},
			hasValue: {
				invalid: foundationClasses.textInvalid
			}
		}
	},
	textarea: {
		label: {
			unfocused: {
				base: `w-fit ${foundationClasses.fieldPadding}`
			}
		}
	},
	rating: {
		fieldWrapper: {
			base: 'flex items-center gap-2'
		},
		legend: {
			base: foundationClasses.labelColor
		},
		field: {
			base: 'hidden'
		},
		label: {
			base: 'cursor-pointer text-amber-400 hover:scale-125 transition duration-400 ease-out'
		},
		validationMessage: {
			base: 'italic'
		}
	},
	select: {
		field: {
			base: `flex border outline-none text-black ${foundationClasses.fieldShape} ${foundationClasses.fieldPadding} pr-12 min-w-fit ${foundationClasses.transition}`
		}
	},
	radio: {
		fieldWrapper: {
			base: 'flex items-center'
		},
		field: {
			base: '',
			unfocused: {
				base: '',
				valid: '',
				invalid: ''
			},
			focused: {
				base: '',
				valid: '',
				invalid: ''
			},
			hasValue: {
				base: '',
				valid: '',
				invalid: ''
			}
		},
		legend: {
			base: foundationClasses.labelColor
		},
		label: {
			base: 'flex gap-2 items-center',
			unfocused: {
				base: '',
				valid: '',
				invalid: ''
			},
			focused: {
				base: '',
				valid: '',
				invalid: ''
			},
			hasValue: {
				base: '',
				valid: '',
				invalid: ''
			}
		},
		validationMessage: {
			base: 'italic'
		}
	},
	checkbox: {
		fieldWrapper: {
			base: 'flex items-center mt-3'
		},
		field: {
			base: ''
		},
		legend: {
			base: foundationClasses.labelColor
		},
		label: {
			base: foundationClasses.labelColor + ' flex gap-2 items-center',
			unfocused: {
				base: '',
				valid: '',
				invalid: ''
			},
			focused: {
				base: '',
				valid: '',
				invalid: ''
			},
			hasValue: {
				base: '',
				valid: '',
				invalid: ''
			}
		},
		validationMessage: {
			base: 'italic'
		}
	},
	hidden: {
		fieldWrapper: {
			base: 'hidden',
			unfocused: {
				base: '',
				valid: '',
				invalid: ''
			},
			focused: {
				base: '',
				valid: '',
				invalid: ''
			},
			hasValue: {
				base: '',
				valid: '',
				invalid: ''
			}
		}
	},
	date: {
		label: {
			unfocused: {
				base: `h-[2px] text-sm p-1 ml-2 -mt-1 bg-white`,
				valid: foundationClasses.labelColor,
				invalid: foundationClasses.textInvalid
			}
		}
	},
	'datetime-local': {
		label: {
			unfocused: {
				base: `h-[2px] text-sm p-1 ml-2 -mt-1 bg-white`,
				valid: foundationClasses.labelColor,
				invalid: foundationClasses.textInvalid
			}
		}
	},
	time: {
		label: {
			unfocused: {
				base: `h-[2px] text-sm p-1 ml-2 -mt-1 bg-white`,
				valid: foundationClasses.labelColor,
				invalid: foundationClasses.textInvalid
			}
		}
	}
}

function buildFieldTypes(unMergedTypes:ClassesByFieldType) {

	const mergiciaAlt = mergician({})

	const builtFieldTypes:ByFieldType = { default: {} as FieldTypeObj }
	for (const fieldTypeKey in unMergedTypes) {
		const fieldType = fieldTypeKey as FieldType;

		// merge fieldType with default
		const fieldTypeObj = unMergedTypes[fieldType] || {};
		const defaultTypeObj = unMergedTypes.default || {};
		const mergedType = mergiciaAlt(defaultTypeObj, fieldTypeObj) as FieldTypeObjWithBaseClasses

		const newType = {} as FieldTypeObj;

		// Build Classes
		for (const fieldPartKey in mergedType) {
			const fieldPart = fieldPartKey as FieldPart;
			newType[fieldPart] = {} as FieldPartObj;
			const focusStateBase = mergedType?.[fieldPart]?.base || '';

			for (const focusStateKey of focusStates) {
				const focusState = focusStateKey as FocusState;
				newType[fieldPart][focusState] = {} as FocusStateObj;
				const validStateBase = mergedType?.[fieldPart]?.[focusState]?.base || '';

				for (const validStateKey of validStates) {
					const validState = validStateKey as ValidState;

					const validStateClasses = mergedType?.[fieldPart]?.[focusState]?.[validState] || ''

					newType[fieldPart][focusState][validState] = twMerge([focusStateBase, validStateBase,validStateClasses])

				}
			}
		}
		builtFieldTypes[fieldType] = newType
	}

	return builtFieldTypes
}
interface FormClasses {
	foundation: FoundationClasses;
	byFieldType: ByFieldType
}
const formClasses:FormClasses = {
	foundation: foundationClasses,
	byFieldType: buildFieldTypes(classesByFieldType)
}

type GetFieldPartClass = (params:{ fieldType:FieldType; fieldPart:FieldPart; isFocused:boolean; hasValue:boolean; isValid:boolean; name?:string; }) => string;
const getFieldPartClass:GetFieldPartClass = function({ fieldType, fieldPart, isFocused, hasValue, isValid, name }) {

	const focusState:FocusState = isFocused ? 'focused' : hasValue ? 'hasValue' : 'unfocused'
	const validState:ValidState = isValid ? 'valid' : 'invalid'

	let validStateClasses = formClasses.byFieldType?.[fieldType]?.[fieldPart]?.[focusState]?.[validState]
	if (typeof validStateClasses === 'undefined') validStateClasses = formClasses.byFieldType.default?.[fieldPart]?.[focusState]?.[validState] || ''

	return  validStateClasses
}
type GetFieldClassesParams = { fieldType:FieldType; isFocused:boolean; hasValue:boolean; isValid:boolean; isHidden:boolean, name?:string };
const getFieldClasses = function({ fieldType, isFocused=false, hasValue=false, isValid=true, isHidden=false, name }:GetFieldClassesParams) {

	return {
		fieldWrapper: getFieldPartClass({ fieldType: isHidden ? 'hidden' : fieldType, fieldPart: 'fieldWrapper', isFocused, hasValue, isValid, name }),
		legend: getFieldPartClass({ fieldType, fieldPart: 'legend', isFocused, hasValue, isValid, name }),
		field: getFieldPartClass({ fieldType, fieldPart: 'field', isFocused, hasValue, isValid, name }),
		label: getFieldPartClass({ fieldType, fieldPart: 'label', isFocused, hasValue, isValid, name }),
		validationMessage: getFieldPartClass({ fieldType, fieldPart: 'validationMessage', isFocused, hasValue, isValid, name })
	}
}
// console.log('formClasses',formClasses);

export { formClasses, getFieldClasses}
